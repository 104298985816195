<template>
  <b-container fluid>

    <b-row class="mb-4">
      <b-col class="mb-3 mb-md-0" md="4">
        <h1 class="font-weight-bold mb-0 text-primary">Financial</h1>
      </b-col>
      <b-col class="mb-3 mb-md-0" md="8">
        <DateRangePicker :from="fromDate" :to="toDate" @input="input => { this.fromDate = input.date1; this.toDate = input.date2; this.doFetchStatistics() }"></DateRangePicker>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" md="6" class="mt-4 mt-md-0">
        <b-card no-body class="d-flex flex-column align-items-center justify-content-center shadow-sm p-4">
          <h4 class="mb-3 text-center">Marketing Spend</h4>
          <h2 class="mb-0 text-muted" v-if="!financeData.marketing_spend.loading">{{ financeData.marketing_spend.value | currencyFormat }}</h2>
          <b-spinner v-if="financeData.marketing_spend.loading" variant="secondary"></b-spinner>
        </b-card>
      </b-col>
      <b-col cols="12" md="6" class="mt-4 mt-md-0">
        <b-card no-body class="d-flex flex-column align-items-center justify-content-center shadow-sm p-4">
          <h4 class="mb-3 text-center">Revenue</h4>
          <h2 class="mb-0 text-muted" v-if="!financeData.revenue.loading">{{ financeData.revenue.value | currencyFormat }}</h2>
          <b-spinner v-if="financeData.revenue.loading" variant="secondary"></b-spinner>
        </b-card>
      </b-col>
      <b-col cols="12" md="6" class="mt-4 mt-md-0 d-none">
        <b-card no-body class="d-flex flex-column align-items-center justify-content-center shadow-sm p-4">
          <h4 class="mb-3 text-center">ROAS</h4>
          <h2 class="mb-0 text-muted" v-if="!financeData.roas.loading">{{ financeData.roas.value }}</h2>
          <b-spinner v-if="financeData.roas.loading" variant="secondary"></b-spinner>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-0 mt-md-4">
      <b-col cols="12" md="4" class="mt-4 mt-md-0">
        <b-card no-body class="d-flex flex-column align-items-center justify-content-center shadow-sm p-4">
          <h4 class="mb-3 text-center">Finance Applications</h4>
          <h2 class="mb-0 text-muted" v-if="!financeData.finance_applications.loading">{{ financeData.finance_applications.values.total }}</h2>
          <b-spinner v-if="financeData.finance_applications.loading" variant="secondary"></b-spinner>
        </b-card>
      </b-col>
      <b-col cols="12" md="4" class="mt-4 mt-md-0">
        <b-card no-body class="d-flex flex-column align-items-center justify-content-center shadow-sm p-4">
          <h4 class="mb-3 text-center">Finance Accepted</h4>
          <h2 class="mb-0 text-muted" v-if="!financeData.finance_applications.loading">{{ financeData.finance_applications.values.accepted }}</h2>
          <b-spinner v-if="financeData.finance_applications.loading" variant="secondary"></b-spinner>
        </b-card>
      </b-col>
      <b-col cols="12" md="4" class="mt-4 mt-md-0">
        <b-card no-body class="d-flex flex-column align-items-center justify-content-center shadow-sm p-4">
          <h4 class="mb-3 text-center">Finance Declined</h4>
          <h2 class="mb-0 text-muted" v-if="!financeData.finance_applications.loading">{{ financeData.finance_applications.values.declined }}</h2>
          <b-spinner v-if="financeData.finance_applications.loading" variant="secondary"></b-spinner>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-0 mt-md-4">
      <b-col cols="12" md="4" class="mt-4 mt-md-0">
        <b-card no-body class="d-flex flex-column align-items-center justify-content-center shadow-sm p-4">
          <h4 class="mb-3 text-center">Impression Kits</h4>
          <h2 class="mb-0 text-muted" v-if="!financeData.conversions.loading">{{ financeData.conversions.values.impression_kits }}%</h2>
          <small class="text-muted" v-if="!financeData.conversions.loading">First Purchases</small>
          <div class="w-100" v-if="!financeData.conversions.loading">
            <hr>
          </div>
          <p class="text-muted mb-0" v-if="!financeData.conversions.loading">{{ financeData.conversions.values.aligners_after_impression_kits }}%</p>
          <small class="text-muted" v-if="!financeData.conversions.loading">Bought Aligners</small>
          <b-spinner v-if="financeData.conversions.loading" variant="secondary"></b-spinner>
        </b-card>
      </b-col>
      <b-col cols="12" md="4" class="mt-4 mt-md-0">
        <b-card no-body class="d-flex flex-column align-items-center justify-content-center shadow-sm p-4">
          <h4 class="mb-3 text-center">Scan Appointments</h4>
          <h2 class="mb-0 text-muted" v-if="!financeData.conversions.loading">{{ financeData.conversions.values.scan_appointments }}%</h2>
          <small class="text-muted" v-if="!financeData.conversions.loading">First Purchases</small>
          <div class="w-100" v-if="!financeData.conversions.loading">
            <hr>
          </div>
          <p class="text-muted mb-0" v-if="!financeData.conversions.loading">{{ financeData.conversions.values.aligners_after_scan_appointments }}%</p>
          <small class="text-muted" v-if="!financeData.conversions.loading">Bought Aligners</small>
          <b-spinner v-if="financeData.conversions.loading" variant="secondary"></b-spinner>
        </b-card>
      </b-col>
      <b-col cols="12" md="4" class="mt-4 mt-md-0">
        <b-card no-body class="d-flex flex-column align-items-center justify-content-center shadow-sm p-4">
          <h4 class="mb-3 text-center">Aligners</h4>
          <h2 class="mb-0 text-muted" v-if="!financeData.conversions.loading">{{ financeData.conversions.values.aligners }}%</h2>
          <small class="text-muted" v-if="!financeData.conversions.loading">First Purchases</small>
          <div class="w-100" v-if="!financeData.conversions.loading">
            <hr>
          </div>
          <p class="text-muted mb-0" v-if="!financeData.conversions.loading">{{ financeData.conversions.values.aligners_after_initial_purchase }}%</p>
          <small class="text-muted" v-if="!financeData.conversions.loading">Subsequent Purchases</small>
          <b-spinner v-if="financeData.conversions.loading" variant="secondary"></b-spinner>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-0 mt-md-4">
      <b-col cols="12" md="4" class="mt-4 mt-md-0">
        <b-card no-body class="d-flex flex-column align-items-center justify-content-center shadow-sm p-4">
          <h4 class="mb-3">CAC</h4>
          <h2 class="mb-0 text-muted" v-if="!financeData.cac.loading">{{ financeData.cac.value | currencyFormat }}</h2>
          <b-spinner v-if="financeData.cac.loading" variant="secondary"></b-spinner>
        </b-card>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import DateRangePicker from '../../components/DateRangePicker'
import currentUser from '../../mixins/currentUser'
import momentMixin from '../../mixins/momentMixin'
import numberMixin from '../../mixins/numberMixin'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [currentUser, momentMixin, numberMixin],
  data: () => ({
    fromDate: null,
    toDate: null
  }),
  created () {
    this.fromDate = this.moment().subtract(1, 'month').toDate()
    this.toDate = this.moment().toDate()
    this.doFetchStatistics()
  },
  components: {
    FriendlyDate,
    DateRangePicker
  },
  computed: {
    ...mapGetters('financial', {
      'financeData': 'data'
    })
  },
  methods: {
    ...mapActions('financial', [
      'fetchMarketingSpend',
      'fetchRevenue',
      'fetchRoas',
      'fetchFinanceApplications',
      'fetchConversionStatistics',
      'fetchCac'
    ]),

    doFetchStatistics() {
      var data = {
        from: this.moment(this.fromDate).format('DD/MM/YYYY'),
        to: this.moment(this.toDate).format('DD/MM/YYYY'),
      }

      this.fetchMarketingSpend(data)
      this.fetchRevenue(data)
      this.fetchRoas(data)
      this.fetchFinanceApplications(data)
      this.fetchConversionStatistics(data)
      this.fetchCac(data)
    }
  },
}
</script>

<style lang="scss">

.tabs {

  .card-header {
    border: 1px solid rgba(0, 0, 0, 0.125);
  }

  .tab-content {
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-top: unset;
  }

}



</style>
